.search-bar-container {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 50vw;
}

.search-container {
    flex-direction: column;
    padding: 10px;
    border-radius: 18px 18px 0px 0px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid transparent;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.search-bar {
    background: white;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 8px;
    font-size: 24px;
    color: black;
    text-align: left;
    z-index: 1;
    padding-left: 25px;
}

.warning-text {
    color: white;
    font-size: 14px;
    /*margin-top: 20px;*/
    text-align: center;
}

.search-bar:focus {
    outline: none;
}

.search-button {
    position: absolute;
    right: 0;
    z-index: 1;
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px;
}

.loading-spinner-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    border: none;
    background: none;
    cursor: pointer;
    padding: 12.5px;
}

.search-button:focus {
    outline: none;
}

.send-icon {
    content: url('../../send-icon.svg');
    width: 40px;
    height: 40px;
    color: white;
}

.search-suggestion-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 25px;
}

.search-suggestion-capsule {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-suggestion-capsule:hover {
    background-color: #f0f0f0;
}

.search-suggestion-capsule h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
    text-align: center;
}

.search-results {
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    display: grid;
    justify-items: center;
}

.markdown {
    max-width: 70vw;
    padding-left: 20px;
    padding-right: 20px;
}

/* Add this at the end of your SearchBar.css */

@media (max-width: 600px) {
    .search-suggestion-capsule {
        background-color: white;
        padding: 15px;
        flex-direction: row;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .search-bar-container {
        width: 95vw;
        flex-direction: row;
        justify-content: center;
        height: 5vh;
        margin-bottom: 25px;
    }

    .search-bar {
        width: 90vw;
        height: 5vh;
        font-size: 18px;
    }

    .send-icon {
        width: 30px;
        height: 30px;
    }

    .search-results {
        margin-top: 10px;
        margin-bottom: 10px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .message-container {
        display: flex;
        justify-content: flex-start;
        width: auto;
        margin: 10px 0;
        word-wrap: normal;
    }

    .message-user {
        background-color: #007bff;
        color: white;
        padding: 10px;
        border-radius: 15px;
        align-self: flex-end;
        max-width: 70%;
        width: fit-content;
        margin-left: auto;
        text-align: right;
    }

    .message-assistant {
        background-color: #f0f0f0;
        color: black;
        padding: 10px;
        border-radius: 15px;
        align-self: flex-start;
        max-width: 70%;
        margin-right: auto;
        text-align: left;
    }
}
