.search-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
}

.header {
    font-size: 48px;
    color: white;
    margin-bottom: 20px;
    text-shadow: 0 0 10px rgba(1, 1, 1, 0.5); /* Change this line */
}

.header h1 {
    font-size: 64px;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.linkedin-icon {
    content: url('../../linkedin-logo.svg');
    width: 50px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
}

.github-icon {
    content: url('../../github-logo.svg');
    width: 50px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
}

.mail-icon {
    content: url('../../mail-logo.svg');
    width: 50px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .search-view {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 95vh;
    }

    .header {
        text-align: center;
        font-size: 32px;
    }

    .socials {
        justify-content: center;
    }
}
